<template>
    <div class="content">
        <article id="login-main">
            <div class="container">
                <div class="module center">
                    <h2 class="-dark">Select Account To Login</h2>
                    <div class="account-box">
                        <a class="-item" href="https://portal.northpeakpay.com/" target="_blank">
                            <div class="-image">
                                <img class="objectImg" src="@/assets/images/account/payment-account-icon.png"/>
                            </div>
                            <div class="-label">Payment Account</div>
                        </a>
                        <a class="-item" href="https://gate.northpeakpay.com" target="_blank">
                            <div class="-image">
                                <img class="objectImg" src="@/assets/images/account/merchant-account-icon.png"/>
                            </div>
                            <div class="-label">Merchant Account</div>
                        </a>
                    </div>
                </div>
            </div>
        </article>
    </div>
</template>

<script>
export default {
    name: "Login"
}
</script>

<style scoped>

</style>