<template>
    <div class="content">
        <article id="about-main">
            <div class="container">
                <h2>About NorthPeak Pay</h2>
                <p class="-light -medium">NorthPeak Pay provides a wide range of financial services, such as SWIFT and SEPA payments, debit cards, currency exchange and virtual currencies operations for small and medium businesses, freelancers and individuals.</p>
                <p class="-light -medium">We believe that payments and other financial transactions can be done in a few clicks and shouldn't slow you down on your way to your goals.</p>
            </div>
        </article>

        <div class="context">
            <article id="about-mission">
                <div class="container">
                    <div class="module double">
                        <div class="content-left">
                            <h3 class="-dark --mobile">Our Mission</h3>
                            <div class="-illustration">
                                <img class="objectImg" src="@/assets/images/about/img-mission.png"/>
                            </div>
                        </div>
                        <div class="content-right">
                            <div class="-description">
                                <h3 class="-dark --web">Our Mission</h3>
                                <p class="-medium -dark">We provide a wide range of financial services through a network of reliable payment institutions, providing a flexible system of multi-currency accounts and related services. We help our clients manage their financial assets simply and efficiently.</p>
                            </div>
                        </div>
                    </div>

                    <div class="module double -reverse">
                        <div class="content-left">
                            <div class="-description">
                                <h3 class="-dark --web">Our Vision</h3>
                                <p class="-medium -dark">We shall become a highly effective and reliable online money service business, offering customers a comfortable working environment based on the latest achievements of the fintech industry.</p>
                            </div>
                        </div>
                        <div class="content-right">
                            <h3 class="-dark --mobile">Our Vision</h3>
                            <div class="-illustration">
                                <img class="objectImg" src="@/assets/images/about/img-vision.png"/>
                            </div>
                        </div>
                    </div>
                </div>
            </article>

            <article id="about-values">
                <div class="container">
                    <div class="module center">
                        <h3 class="-dark">Our Values</h3>
                        <h2 class="-dark">We Believe Money Can Be Managed In A New Way</h2>
                        <div class="values-box">
                            <div class="value-item">
                                <div class="-icon">
                                    <img class="objectImg" src="@/assets/images/about/value-1.svg"/>
                                </div>
                                <h3 class="-dark">It Can Be Done At A Fair Price</h3>
                                <p class="-medium -dark">We are all about fair prices and transparent conditions. We do not support hidden fees and unreasonable contract terms.</p>
                            </div>
                            <div class="value-item">
                                <div class="-icon">
                                    <img class="objectImg" src="@/assets/images/about/value-2.svg"/>
                                </div>
                                <h3 class="-dark">It Can Be Done In A Few Clicks</h3>
                                <p class="-medium -dark">Banking should be efficient. In a modern digital world being fast and easy is a must and it is exactly what NorthPeak Pay is.</p>
                            </div>
                            <div class="value-item">
                                <div class="-icon">
                                    <img class="objectImg" src="@/assets/images/about/value-3.svg"/>
                                </div>
                                <h3 class="-dark">It Can Be Done Simply Yet Safe</h3>
                                <p class="-medium -dark">Our services are based on modern IT solutions, and experienced banking professionals ensure the smooth and safe operational process.</p>
                            </div>
                        </div>
                        <a v-on:click="changeRoute('account')" class="ui-btn -primary" target="_blank" theme="dark">get started now</a>
                    </div>
                </div>
            </article>
        </div>
    </div>
</template>

<script>
    export default {
        name: "About",
      methods: {
        changeRoute(name) {
          this.$router.push({name})
        }
      }
    }
</script>

<style lang="scss" scoped>
    .context {
        /*background: radial-gradient(176.94% 75.1% at 109.62% 17.77%, rgba(119, 159, 211, 0.2) 47.37%, rgba(119, 159, 211, 0) 100%), #E7EBF4;*/
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("~@/assets/images/about/background-web.png");

        @media (max-width:767px) {
            background-image: url("~@/assets/images/about/background-mobile.png");
        }
    }
</style>