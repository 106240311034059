<template>
  <div class="content">
    <article id="individual-main">
      <div class="container">
        <div class="module center">
          <h3 class="-dark">One more step to get account</h3>
          <h2 class="-dark">please complete the Application form</h2>
          <div class="form-holder">
            <form>

                <div class="input-flex">
                    <SimpleInput type="text" placeholder="Full Name" v-on:changeInput="(value) => fullName = value"/>
                    <CountryInput v-on:changeCountry="(value) => countryOfCitizenship = value"
                                  placeholder="Country of Citizenship"/>
                </div>

                <div class="input-flex">
                    <SimpleInput type="text" placeholder="Address of Residence"
                                 v-on:changeInput="(value) => addressOfResidence = value"/>
                    <CountryInput v-on:changeCountry="(value) => countryOfResidence = value"
                                  placeholder="Country of Residence"/>
                </div>

                <div class="input-flex">
                    <TelephoneInput v-on:changePhone="(value) => setPhone(value)"/>
                    <SimpleInput type="email" placeholder="Email address" v-on:changeInput="(value) => email = value"/>
                </div>


              <div class="input-flex">
                <SimpleInput type="text" placeholder="Occupation/Professional activity"
                             v-on:changeInput="(value) => professionalActivity = value"/>
                  <div class="input-flex-double">
                      <SimpleInput type="number" placeholder="Planned monthly turnover" :hint="true"
                                   :hint-text="hintTurnover" v-on:changeInput="(value) => currency = value"/>
                      <CurrencySelect  v-on:changeCurrency="(value) => currencyPlaned = value"/>
                  </div>
              </div>


              <SimpleInput type="textarea"
                           placeholder="Detailed description of the Purpose of account opening"
                           v-on:changeInput="(value) => detailed = value"/>

                <div class="captcha-box">
                    <vue-recaptcha v-show="showRecaptcha" :sitekey="getCaptchaKey()"
                                   size="normal"
                                   theme="light"
                                   hl="en"
                                   :load-recaptcha-script="true"
                                   @verify="handleSuccess"
                                   @fail="handleError"
                                   @expire="recaptchaExpired"
                                   ref="vueRecaptcha">
                    </vue-recaptcha>
                  <div class="captcha-error" v-if="captchValid === false">Error captcha</div>
                </div>


              <div class="ui-btn -primary" theme="dark" v-on:click="send">Apply for individual account</div>

              <div class="form-footer">
                <span>View Our</span><a class="-link" href="/northpeak_privacy_policy.pdf" target="_blank">Privacy
                Policy</a>
              </div>

            </form>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script>

import SimpleInput from "../components/SimpleInput";
import CountryInput from "../components/CountryInput";
import TelephoneInput from "../components/TelephoneInput";
import vueRecaptcha from 'vue3-recaptcha2';
import CurrencySelect from "../components/CurrencySelect";

export default {
  name: "Individual",
  components: {CurrencySelect, TelephoneInput, CountryInput, SimpleInput, vueRecaptcha},
  data: () => ({
    showRecaptcha: true,
    hintTurnover: 'The anticipated flow of incoming and outgoing funds in the account in EUR equivalent.',
    fullName: {
      value: '',
      hasError: false
    },
    currencyPlaned: {
      value: '',
      hasError: false
    },
    addressOfResidence: {
      value: '',
      hasError: false
    },
    countryOfCitizenship: {
      value: '',
      hasError: false
    },
      countryOfResidence: {
          value: '',
          hasError: false
      },
    professionalActivity: {
      value: '',
      hasError: false
    },
    phone: {
      value: '',
      hasError: false
    },
    email: {
      value: '',
      hasError: false
    },
    currency: {
      value: '',
      hasError: false
    },
    detailed: {
      value: '',
      hasError: false
    },
    processSending: false,
    captchValid: null,
    attachmentName: ''
  }),
  methods: {
    setPhone(value) {
      if (typeof value.phone !== "undefined" && value.phone !== "" && value.phone !== null) {
        this.phone.value = '+' + value.country.dialCode + value.phone
        this.phone.hasError = false
      } else {
        this.phone.hasError = true
      }
    },
    getCaptchaKey() {
      return process.env.VUE_APP_CAPTCHA_KEY
    },
    uploadFile() {
      this.images = this.$refs.file.files[0];
      this.attachmentName = this.$refs.file.files[0].name;
    },
    handleSuccess(response) {
      this.captchValid = true
      console.log(response)
    },
    recaptchaExpired() {
      this.captchValid = false
      this.$refs.vueRecaptcha.reset();
    },
    handleError() {
      this.captchValid = false
    },
    send() {
      this.emitter.emit('validate')

      if (this.captchValid === null) {
        this.captchValid = false
        return;
      }

      if (this.fullName.hasError
        || this.addressOfResidence.hasError
        || this.countryOfCitizenship.hasError
        || this.countryOfResidence.hasError
        || this.professionalActivity.hasError
        || this.phone.hasError
        || this.email.hasError
        || this.currency.hasError
        || this.currencyPlaned.hasError
        || this.detailed.hasError
        || !this.captchValid
      ) {
        return
      }

      if ( typeof this.fullName.value == 'undefined'
        || typeof this.addressOfResidence.value == 'undefined'
        || typeof this.countryOfCitizenship.value.name == 'undefined'
        || typeof this.countryOfResidence.value.name == 'undefined'
        || typeof this.professionalActivity.value == 'undefined'
        || typeof this.phone.value == 'undefined'
        || typeof this.email.value == 'undefined'
        || typeof this.currency.value == 'undefined'
        || typeof this.currencyPlaned.value == 'undefined'
        || typeof this.detailed.value == 'undefined'
      ) {
        return
      }

      if (this.processSending) {
        return;
      }

      this.processSending = true
      let bodyFormData = new FormData();
      bodyFormData.append('fullName', this.fullName.value);
      bodyFormData.append('addressOfResidence', this.addressOfResidence.value);
      bodyFormData.append('countryOfCitizenship', this.countryOfCitizenship.value.name);
      bodyFormData.append('countryOfResidence', this.countryOfResidence.value.name);
      bodyFormData.append('professionalActivity', this.professionalActivity.value);
      bodyFormData.append('email', this.email.value);
      bodyFormData.append('phone', this.phone.value);
      bodyFormData.append('currency', this.currency.value);
      bodyFormData.append('detailed', this.detailed.value);
      bodyFormData.append('currencyPlaned', this.currencyPlaned.value);

      this.axios.post('individualMailer.php', bodyFormData, {headers: {crossdomain: true},}).then(response => {
        this.isMailSend = response.data.status === 'ok'
        this.mailSendType = response.data.status === 'ok' ? 'send' : 'error'

        if (response.data.status === 'ok') {
          this.emitter.emit('openModal', {isOpen: true, componentModal: 'SuccessModal', data: {}})
          this.clear()
        } else {
          this.emitter.emit('openModal', {isOpen: true, componentModal: 'FailModal', data: {}})
        }
        this.$refs.vueRecaptcha.reset();
        this.processSending = false
        setTimeout(() => {
          this.isMailSend = false
          this.mailSendType = ''
        }, 1000)
      }).catch(e => {
        if (typeof e.message !== 'undefined' && e.message === 'Request failed with status code 404') {
          this.emitter.emit('openModal', {isOpen: true, componentModal: 'FailModal', data: {}})
        } else {
          this.clear()
          this.$refs.vueRecaptcha.reset();
        }

        this.processSending = false
        setTimeout(() => {
          this.isMailSend = false
          this.mailSendType = ''
        }, 1000)

        console.log(e)
      })
    },
    clear() {
      this.fullName.value = ''
      this.addressOfResidence.value = ''
      this.countryOfCitizenship.value = ''
      this.countryOfResidence.value = ''
      this.professionalActivity.value = ''
      this.email.value = ''
      this.phone.value = ''
      this.currency.value = ''
      this.detailed.value = ''
      this.captchValid = null
      this.emitter.emit('clear')
    }
  }
}
</script>

<style lang="scss" scoped>
.input-flex-double {
    flex: 1 1;
    display: flex;
    flex-wrap: nowrap;
    align-items: baseline;

    .input-holder:nth-child(1){
        margin-left: 30px !important;
        margin-right: 10px !important;
        flex: 1 1 !important;

        @media (max-width:768px) {
            margin-left: 0px !important;
        }
    }

    .input-holder:nth-child(2) {
        margin-right: 0px !important;
        margin-left: 30px !important;

        @media (max-width:768px) {
            margin-right: 0px !important;
        }
    }
}

</style>