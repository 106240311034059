<template>
  <div class="modal-content">
    <div class="--icon"></div>
    <div class="--title">Success!</div>
    <div class="--message">Your message was successfully sent! We will get back to you within 24 hours.</div>
    <div class="--button">
      <a class="ui-btn -primary" theme="dark" v-on:click="this.emitter.emit('openModal', {isOpen: false, componentModal: 'SuccessModal', data: {}})">close window</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "SuccessModal"
}
</script>

<style lang="scss" scoped>

.modal-content {
  padding: 80px;
  max-width: 450px;
}

.--icon {
  position: absolute;
  top: -50px;
  left: calc(50% - 50px);

  width: 100px;
  height: 100px;
  border-radius: 100%;
  background-color: #E7EBF4;

  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url("~@/assets/images/icons/success-icon.svg");
}

.--title {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #283663;
  padding-bottom: 20px;
}

.--message {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #283663;
}

.--button {
  padding-top: 60px;
  text-align: center;
}

</style>