<template>
  <div :class="hasError ? '--error' : ''" class="input-holder">
    <div v-click-outside="onClickOutside" :class="listShow ? 'open' : ''" class="select-box" toggl-position="right"
         type="select">
      <div class="select">
        <div class="toggl-btn" v-on:click="listShow = !listShow">
          <div class="option-selected">
            <span>{{selectedCurrency}}</span>
          </div>
          <div class="toggl-ic"></div>
        </div>
      </div>
      <div ref="list" class="list">
        <div class="options">
          <div class="option" v-for="(currency, i) in getCurrencies()" :key="i">
            <span class="-name" v-on:click="selectCurrency(currency)">{{currency}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="input-error">Field can not be empty</div>
  </div>
</template>

<script>
import vClickOutside from "click-outside-vue3";

export default {
  name: "CurrencySelect",
  data: () => ({
    listShow: false,
    selectedCurrency: "",
    hasError: false,
  }),
  emits: ['changeCurrency'],
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    getCurrencies() {
      return process.env.VUE_APP_CURRENCY.split(',')
    },
    selectCurrency(currency) {
      this.hasError = false;
      this.listShow = false
      this.selectedCurrency = currency

      this.$emit('changeCurrency', {value: currency, hasError: this.hasError})
    },
    onClickOutside() {
      this.listShow = false
    },
  },
  mounted() {
    this.emitter.on("validate", () => {
      this.hasError = this.selectedCurrency === '';
    });

    this.emitter.on("clear", () => {
      this.selectCurrency(this.getCurrencies()[0])
    });
  },
  beforeMount() {
    this.selectCurrency(this.getCurrencies()[0])
  }
}
</script>

<style lang="scss" scoped>

.input-holder {
  width: 150px !important;

    @media (max-width: 767px) {
        width: 75px !important;
    }
}

.select-box {
  position: relative;

  &.open {
    .list {
      visibility: visible;
    }
  }

  &[type='select'] {
    .toggl-btn {
      width: 100%;
      justify-content: space-between;
    }
  }

  &[type='input-select'] {
    input {
      flex: 1 1;
    }

    &[toggl-position='right'] {
      .select {
        flex-direction: row;
      }
    }

    &[toggl-position='left'] {
      .select {
        flex-direction: row-reverse;
      }
    }
  }
}

///
.select {
  position: relative;
  box-sizing: border-box;
  outline: none;

  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  padding-bottom: 12px;

  font-family: 'Inter', sans-serif;
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  color: #1E2E50;

  background-color: transparent;

  border-bottom: 1px solid #283663;

  .-placeholder {
    color: #7881A2;
  }
}

///
.toggl-btn {
  position: relative;
  cursor: pointer;
  padding: 0px;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
  background-color: transparent;
  border: none;
  transition: all 0.2s ease-in;

  height: 30px;

  &:hover {
    .toggl-ic {
      transform: translateY(2px);
      transition: transform ease-out 0.2s;
    }
  }

  .toggl-ic {
    height: 23px;
    width: 12px;
    margin-left: 8px;
    transition: transform 0.2s;

    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("~@/assets/images/icons/ic-chevron-down.svg");
  }
}

///
.list {
  position: absolute;
  z-index: 1;
  width: 100%;
  top: 52px;
  left: 0;
  min-height: 20px;
  overflow: hidden;
  transition: all 0.2s ease-in;

  background: #F1F3F9;
  box-shadow: 0px 10px 15px rgba(40, 54, 99, 0.15);
  border-radius: 10px;

  visibility: hidden;

  .options {
    max-height: 125px;
    overflow-y: auto;
    padding-bottom: 5px;
    padding-top: 5px;
  }

  .no-option {
    text-align: center;
    padding: 16px 16px;
    color: rgba(161, 174, 195, 0.5);
  }

  .option {
    display: flex;
    align-items: center;
    padding: 0px 28px;

    &:hover {
      cursor: pointer;
      background-color: #E7EBF4;
    }

    .normal-flag {
      margin: 0px !important;
    }

    .-name {
      padding: 0px 10px;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #283663;
    }

    .-code {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #779FD3;
    }
  }
}

///
.search {
  padding: 40px 40px 0px 40px;

  .search-input-wrap {
    position: relative;
  }

  .search-input {
    box-sizing: border-box;
    outline: none;
    -webkit-appearance: none;
    font-family: 'Inter', sans-serif;

    width: 100%;
    padding: 0px 25px 12px 0px;
    font-size: 16px;
    font-weight: 400;

    height: 30px;
    background-color: transparent;
    color: #283663;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #283663;

    caret-color: #283663 !important;
  }
}

///
.options::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}

.options::-webkit-scrollbar-track {
  background-color: #C4CADA;
  border-radius: 30px;
}

.options::-webkit-scrollbar-thumb {
  background-color: #779FD3;
  background-clip: padding-box;
  border-radius: 30px;
}

</style>