<template>
    <div class="content">
        <div id="page-services" class="context">
            <div class="container">
                <h2 class="-dark">Our Services</h2>
                <div id="services0" class="module double">
                    <div class="content-left">
                        <h3  class="-dark --mobile">Individual and Business Accounts With Individual IBANs</h3>
                        <div class="-illustration">
                            <img class="objectImg" src="@/assets/images/services/illustration-service-1.png"/>
                        </div>
                    </div>
                    <div class="content-right">
                        <div class="-description">
                            <h3 class="-dark --web">Individual and Business Accounts<br/> With Individual IBANs</h3>
                            <p class="-medium -dark">Each payment account at NorthPeak Pay has an International Bank Account Number or IBAN. IBAN allows fast and secure international payments, bulk payments, online currency conversion and more.
                            </p>
                            <a v-on:click="changeRoute('account')" class="ui-btn -primary" target="_blank" theme="dark">get started now</a>
                        </div>
                    </div>
                </div>

                <div id="services1" class="module double -reverse">
                    <div class="content-left">
                        <div class="-description">
                            <h3  class="-dark --web">Payments and Currency Exchange</h3>
                            <p class="-medium -dark">We support SWIFT and SEPA transfers. Send and receive international payments in various currencies in a fast and secure way.</p>
                            <a v-on:click="changeRoute('account')" class="ui-btn -primary" target="_blank" theme="dark">get started now</a>
                        </div>
                    </div>
                    <div class="content-right">
                        <h3 class="-dark --mobile">Payments and Currency Exchange</h3>
                        <div class="-illustration">
                            <img class="objectImg" src="@/assets/images/services/illustration-service-2.png"/>
                        </div>
                    </div>
                </div>

                <div id="services2" class="module double">
                    <div class="content-left">
                        <h3 class="-dark --mobile">COMING SOON: Payment Cards</h3>
                        <div class="-illustration">
                            <img class="objectImg" src="@/assets/images/services/illustration-service-3.png"/>
                        </div>
                    </div>
                    <div class="content-right">
                        <div class="-description">
                            <h3 class="-dark --web">COMING SOON: Payment Cards</h3>
                            <p class="-medium -dark">Use a NorthPeak Pay debit card to pay at restaurants, grocery stores and online shops. The card is linked to your account and can be used around the world without additional commissions.</p>
                            <a v-on:click="changeRoute('account')" class="ui-btn -primary" target="_blank" theme="dark">get started now</a>
                        </div>
                    </div>
                </div>

                <div id="services3" class="module double -reverse">
                    <div class="content-left">
                        <div class="-description">
                            <h3 class="-dark --web">Virtual Currency Transactions</h3>
                            <p class="-medium -dark">NorthPeak Pay supports virtual currency operations. Exchange and transfer virtual currency, review the transaction history and be in full control of your virtual assets.</p>
                            <a v-on:click="changeRoute('account')" class="ui-btn -primary" target="_blank" theme="dark">get started now</a>
                        </div>
                    </div>
                    <div class="content-right">
                        <h3 class="-dark --mobile">Virtual Currency Transactions</h3>
                        <div class="-illustration">
                            <img class="objectImg" src="@/assets/images/services/illustration-service-4.png"/>
                        </div>
                    </div>
                </div>

                <div id="services4" class="module double">
                    <div class="content-left">
                        <h3 class="-dark --mobile">Payment Processing</h3>
                        <div class="-illustration">
                            <img class="objectImg" src="@/assets/images/services/illustration-service-5.png"/>
                        </div>
                    </div>
                    <div class="content-right">
                        <div class="-description">
                            <h3 class="-dark --web">Payment Processing</h3>
                            <p class="-medium -dark">Receive online payments from your customers with our payment processing solution. It can be used on your website, online store or other online platform.</p>
                            <a v-on:click="changeRoute('account')" class="ui-btn -primary" target="_blank" theme="dark">get started now</a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "Services",
      methods: {
        changeRoute(name) {
          this.$router.push({name})
        }
      }
    }
</script>

<style lang="scss" scoped>
    .context {
        /*background: radial-gradient(176.94% 75.1% at 109.62% 17.77%, rgba(119, 159, 211, 0.2) 47.37%, rgba(119, 159, 211, 0) 100%), #E7EBF4;*/
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("~@/assets/images/services/background-web.png");

        @media (max-width:767px) {
            background-image: url("~@/assets/images/services/background-mobile.png");
        }
    }
</style>