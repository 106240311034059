import { createApp } from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App.vue'
import router from "./router";
import mitt from 'mitt';
import VueTelInput from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'

const app = createApp(App)
const emitter = mitt();

app.config.globalProperties.emitter = emitter

app.use(VueTelInput);
app.use(router)
app.use(VueAxios, axios)

app.mount('#app')
