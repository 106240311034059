<template>
  <div class="input-holder" :class="hasError ? '--error' : ''">
    <div class="input-wrap">
      <div class="input-tooltip" v-if="hint">
        <div class="tooltip-content">{{hintText}}</div>
      </div>
      <textarea class="ui-input -textarea" v-if="type === 'textarea'" :placeholder="placeholder" ref="textarea" @input="resize()" v-on:keyup="onChange" v-model="value"/>

      <input class="ui-input" v-else :placeholder="placeholder" :type="type" v-model="value" v-on:keyup="onChange"/>
    </div>
    <div class="input-error">{{errorMsg}}</div>
  </div>
</template>

<script>
export default {
  name: "SimpleInput",
  data: () => ({
    value: '',
    hasError: false,
    errorMsg: '',
  }),
  props: {
    hint: {
      type: Boolean,
      required: false,
      default: () => (false)
    },
    hintText: {
      type: String,
      required: false,
      default: () => ('')
    },
    type: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: () => ('')
    },
    validate: {
      type: Function
    },
    isNotRequired: {
      type: Boolean,
      default: () => false
    },
    isNumber: {
      type: Boolean,
      default: () => false,
    }
  },
  methods: {
    validateInput(e) {
      if (this.isNumber && !/\d/.test(e.key)) {
        e.preventDefault();
      } else {
        return true;
      }
    },
    resize() {
      let element = this.$refs["textarea"];

      element.style.height = "18px";
      element.style.height = element.scrollHeight + "px";
    },
    onChange() {
      if (this.isNotRequired && this.value === '') {
        return
      }

      this.hasError = this.value === '';
      this.errorMsg = 'Field can not be empty'

      if (this.type === 'email' && this.value !== '') {
        this.hasError = !/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(this.value);
        this.errorMsg = 'Please enter valid email'
      }

      if (this.type === 'textarea' && this.value !== '') {
        this.hasError = this.value.length < 10 || this.value.length > 400
        this.errorMsg = 'Min 10 max 400 symbols'
      }

      this.$emit('changeInput', {value: this.value, hasError: this.hasError})
    }
  },
  mounted() {
    this.emitter.on("validate", () => {
      this.onChange()
    });

    this.emitter.on("clear", () => {
      this.value = ''
      if (this.type === 'textarea') {
        let element = this.$refs["textarea"];
        if (element != null && typeof element.style !== 'undefined' && element.style != null) {
          element.style.height = "32px";
        }
      }
    });
  }
}

//6LcX57EgAAAAAJ3MfVhNrkJYQRnPRvcseZ_1ZNwF
//6LcX57EgAAAAAKG_GbZ6iNLRCay6rFkxmX-eQxpi
</script>

<style lang="scss" scoped>

.input-tooltip {
  position: absolute;
  top: 0;
  right: 0;
  width: 15px;
  height: 15px;

  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("~@/assets/images/icons/ic-tooltip.svg");

  &:hover {
    .tooltip-content {
      display: block;
    }
  }

  .tooltip-content {
    display: none;
    position: absolute;
    bottom: 25px;
    right: 0px;
    z-index: 100;

    padding: 8px 20px;
    background: #7881A2;
    border-radius: 6px;

    width: 300px;

    color: #FFFFFF;
  }
}

</style>