<template>
  <div class="content">
    <article id="contacts-main">
      <div class="container">
        <div class="module double">
          <div class="content-left">
            <h2 class="-light">Get a free consultation</h2>
            <p><b>Ready to step into the new era of wire transfers?</b><br/>
                Fill in the <router-link to="account" style="color: #779FD3">application form</router-link>!
            </p>
            <p><b>Not ready just yet?</b><br/>
              Contact us and let us know what questions or concerns you have.
            </p>
            <div class="contact-box --web">
              <div class="contact-item">
                <div class="-title">
                  <div class="-icon --email"></div>
                  <h3 class="-light">Email</h3>
                </div>
                <a href="mailto:office@northpeakpay.com">office@northpeakpay.com</a>
              </div>
              <div class="contact-item">
                <div class="-title">
                  <div class="-icon --phone"></div>
                  <h3 class="-light">Phone</h3>
                </div>
                <p>+1 (778) 728-17-88</p>
              </div>
              <div class="contact-item">
                <div class="-title">
                  <div class="-icon --location"></div>
                  <h3 class="-light">Office</h3>
                </div>
                <p>1055 West Georgia Street, Suite #2400A, Vancouver, BC V6E 3P3, Canada</p>
              </div>
            </div>
          </div>

          <div class="content-right">

            <div class="form-holder">
              <div v-if="isMailSend">
                <div v-if="mailSendType === 'send'">Mail successfully send</div>
                <div v-if="mailSendType === 'error'">Mail can`t send</div>
              </div>

              <form>
                <SimpleInput placeholder="Your Name" type="text" v-on:changeInput="(value) => name = value"/>
                <SimpleInput placeholder="Email" type="email" v-on:changeInput="(value) => email = value"/>
                <TelephoneInput @change-phone="onChangePhone"/>

                <div class="input-holder">
                  <div class="switch-input-holder">
                    <span :class="type ? '' : '-active'" class="--label ">Company</span>
                    <Toggle v-model="type"/>
                    <span :class="type ? '-active' : ''" class="--label">Individual</span>
                  </div>
                </div>

                <div class="input-holder">
                  <div class="switch-input-holder">
                    <span :class="isEmail ? '' : '-active'" class="--label">Email me</span>
                    <Toggle v-model="isEmail"/>
                    <div :class="isEmail ? '-active' : ''" class="--label">Call me</div>
                  </div>
                </div>

                <div :class="message.hasError ? '--error' : ''" class="input-holder">
                  <div class="input-wrap">
                    <textarea ref="textarea" v-model="message.value" class="ui-input -textarea"
                              placeholder="Your Message" v-on:keyup="inputChange('message')" @input="resize()"/>
                  </div>
                  <div class="input-error">Field can not be empty</div>
                </div>

                  <div class="input-holder custom-file-input-box" :class="imageHasError ? '--error' : ''">
                      <div class="input-wrap">
                          <input id="file-input" type="file" v-on:change="uploadFile" ref="file" class="custom-file-input" :accept="allowedExtensions">
                          <label for="file-input" v-if="attachmentName !== ''">{{attachmentName}}</label>
                          <label for="file-input" v-else>Add attachment</label>
                          <div class="delete-file-btn" v-on:click="clearAttachment" v-if="attachmentName !== ''"></div>
                      </div>
                      <div class="input-error" v-if="imageHasError">Attached file has not valid file extension</div>
                  </div>


                  <div class="captcha-box">
                      <vue-recaptcha v-show="showRecaptcha" :sitekey="getCaptchaKey()"
                                     size="normal"
                                     theme="light"
                                     hl="en"
                                     :load-recaptcha-script="true"
                                     @verify="handleSuccess"
                                     @fail="handleError"
                                     @expire="recaptchaExpired"
                                     ref="vueRecaptcha">
                      </vue-recaptcha>
                    <div class="captcha-error" v-if="captchValid === false">Error captcha</div>
                  </div>

                <div class="ui-btn -primary" theme="dark" v-on:click="sendMail">send message</div>

                <div class="form-footer">
                  <span>View Our</span><a class="-link" href="/northpeak_privacy_policy.pdf" target="_blank">Privacy
                  Policy</a>
                </div>

              </form>
            </div>

            <div class="contact-box --mob">
              <div class="contact-item">
                <div class="-title">
                  <div class="-icon --email"></div>
                  <h3 class="-light">Email</h3>
                </div>
                <a href="mailto:office@northpeakpay.com">office@northpeakpay.com</a>
              </div>
              <div class="contact-item">
                <div class="-title">
                  <div class="-icon --phone"></div>
                  <h3 class="-light">Phone</h3>
                </div>
                <p>+1 (778) 728-17-88</p>
              </div>
              <div class="contact-item">
                <div class="-title">
                  <div class="-icon --location"></div>
                  <h3 class="-light">Office</h3>
                </div>
                <p>1055 West Georgia Street, Suite #2400A, Vancouver, BC V6E 3P3, Canada</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
import Toggle from '@vueform/toggle'
import TelephoneInput from "../components/TelephoneInput";
import SimpleInput from "../components/SimpleInput";
import vueRecaptcha from 'vue3-recaptcha2';

export default {
  name: "Contacts",
  components: {
    SimpleInput,
    TelephoneInput,
    Toggle,
    vueRecaptcha
    // CountryFlag,
  },
  data: () => ({
    isMailSend: false,
    mailSendType: '',
    name: {
      value: '',
      hasError: false
    },
    message: {
      value: '',
      hasError: false
    },
    email: {
      value: '',
      hasError: false
    },
    type: true,
    isEmail: false,
    phone: {
      value: '',
      country: {},
      hasError: false
    },
    imageHasError: false,
    processSending: false,
    captchValid: null,
    showRecaptcha: true,
    attachmentName: '',
    images: null,
    allowedExtensions: process.env.VUE_APP_ALLOWED_EXTENSIONS
  }),
  methods: {
    clearAttachment() {
      this.$refs.file.value = null
      this.attachmentName = ""
      this.images = null
      this.imageHasError = false
    },
    getCaptchaKey() {
      return process.env.VUE_APP_CAPTCHA_KEY
    },
    uploadFile() {
      if (this.$refs.file.files.length > 0 && typeof this.$refs.file.files[0].name !== "undefined") {
        let type = this.$refs.file.files[0].name.split('.')

        this.imageHasError = this.allowedExtensions.indexOf(type.pop()) === -1
        this.images = this.$refs.file.files[0];
        this.attachmentName = this.$refs.file.files[0].name;
      }
    },
    resize() {
      let element = this.$refs["textarea"];

      element.style.height = "18px";
      element.style.height = element.scrollHeight + "px";
    },
    onChangePhone(value) {
      this.phone.value = value.phone
      this.phone.country = value.country
    },
    inputChange(input) {
      this[input].hasError = this[input].value === '';

      if (input === 'email') {
        this[input].hasError = !/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(this[input].value);
      }
    },
    onInput(phone, phoneObject) {
      if (phoneObject?.number) {
        this.phone.value = phoneObject.number;
      }

      if (typeof this.phone === "undefined" || this.phone === '') {
        if (phoneObject?.formatted) {
          this.phone.value = phoneObject.formatted;
        }
      }
    },
    handleSuccess(response) {
      this.captchValid = true
      console.log(response)
    },
    recaptchaExpired() {
      this.captchValid = false
      this.$refs.vueRecaptcha.reset();
    },
    handleError() {
      this.captchValid = false
    },
    sendMail() {
      this.emitter.emit('validate')
      this.inputChange('message')

      if (this.captchValid === null) {
        this.captchValid = false
        return;
      }

      if (this.name.hasError || this.message.hasError || this.email.hasError || this.phone.hasError || !this.captchValid || this.imageHasError) {
        this.inputChange('name')
        this.inputChange('message')
        this.inputChange('email')
        this.inputChange('phone')

        return
      }

      if (this.processSending) {
        return
      }

      this.processSending = true
      let bodyFormData = new FormData();
      bodyFormData.append('name', this.name.value);
      bodyFormData.append('message', this.message.value);
      bodyFormData.append('email', this.email.value);
      bodyFormData.append('type', this.type ? 'Individual' : 'Company');
      bodyFormData.append('contact', this.isEmail ? 'By phone' : 'By email');
      bodyFormData.append('phone', '+' + this.phone.country.dialCode + this.phone.value);
      bodyFormData.append('file', this.images);

      this.axios.post('mailer.php', bodyFormData, {headers: {crossdomain: true, 'Content-Type': 'multipart/form-data'},}).then(response => {
        this.isMailSend = response.data.status === 'ok'
        this.mailSendType = response.data.status === 'ok' ? 'send' : 'error'
        if (response.data.status === 'ok') {
          this.emitter.emit('openModal', {isOpen: true, componentModal: 'SuccessModal', data: {}})

          this.mailSendType = ''
          this.name.value = ''
          this.message.value = ''
          this.email.value = ''
          this.type = true
          this.isEmail = false
          this.phone.value = ''
          this.phone.country = {}
          this.$refs.file.value = null
          this.attachmentName = ""
          this.captchValid = null
          let element = this.$refs["textarea"];
          element.style.height = "32px";

          this.emitter.emit('clear')
        } else {
          this.emitter.emit('openModal', {isOpen: true, componentModal: 'FailModal', data: {}})
        }

        this.processSending = false
        this.$refs.vueRecaptcha.reset();
        setTimeout(() => {
          this.isMailSend = false
          this.mailSendType = ''
        }, 1000)
      }).catch(e => {
        this.processSending = false
        console.log(e)
        this.emitter.emit('openModal', {isOpen: true, componentModal: 'FailModal', data: {}})
      })
    }
  }
}
</script>

<style src="@vueform/toggle/themes/default.css"></style>
<style lang="scss" scoped>

</style>