<template>
  <Loader v-if="isLoad"/>

  <div class="page">
    <Header/>

    <router-view v-slot="{ Component }">
      <transition mode="out-in" name="fade">
        <component :is="Component"/>
      </transition>
    </router-view>

    <Footer/>
  </div>

  <Modal/>
</template>

<script>

import Header from "./components/Header";
import Footer from "./components/Footer";
import Loader from "./components/Loader";
import Modal from "./components/Modal";

export default {
  name: "App",
  components: {Modal, Loader, Footer, Header},
  data: () => ({
    isLoad: true,
    timer: null
  }),
  beforeMount() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }

    this.timer = setTimeout(() => {
      this.isLoad = false
    }, 500);
  }

}
</script>

<style lang="scss">
@import "@/assets/sass/app.scss";

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}


.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
