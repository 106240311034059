<template>
  <div class="content">
    <article id="business-main">
      <div class="container">
        <div class="module center">
          <h3 class="-dark">One more step to get account</h3>
          <h2 class="-dark">please complete the Application form</h2>

          <div class="form-holder">
            <form>
              <SimpleInput type="text" placeholder="Name of the Company"
                           v-on:changeInput="(value) => companyName = value"/>

              <div class="input-flex">
                <SimpleInput type="text" placeholder="Registration Number"
                             v-on:changeInput="(value) => regNumber = value"/>
                <CountryInput v-on:changeCountry="(value) => countryOfIncorporation = value"
                              placeholder="Country of Incorporation"/>
              </div>

              <SimpleInput type="text" placeholder="Registered Address"
                           v-on:changeInput="(value) => regAddress = value"/>
              <div class="input-flex">
                <SimpleInput type="text" is-not-required placeholder="Business address/Physical location of the Company"
                             v-on:changeInput="(value) => psyAddress = value"/>
                <CountryInput v-on:changeCountry="(value) => countryOfBusiness = value"
                              placeholder="Business Country Location"/>
              </div>


              <div class="input-flex">
                <SimpleInput type="text" placeholder="Website" is-not-required
                             v-on:changeInput="(value) => website = value"/>
                <SimpleInput type="email" placeholder="Corporate Email address (for correspondence)"
                             v-on:changeInput="(value) => corporateEmail = value"/>
              </div>

              <div class="input-flex">
                <SimpleInput type="text" placeholder="Authorized Representative" :hint="true"
                             :hint-text="hintRepresentative" v-on:changeInput="(value) => representative = value"/>
                <TelephoneInput v-on:changePhone="(value) => setPhone(value)"/>
              </div>

              <SimpleInput type="textarea" placeholder="Detailed description of the business of the Company"
                           v-on:changeInput="(value) => description = value"/>

              <div class="input-flex">
                <div class="input-flex-double">
                  <SimpleInput type="number" placeholder="Planned monthly turnover" :hint="true"
                               :hint-text="hintTurnover" v-on:changeInput="(value) => turnoverCurrency = value"/>
                  <CurrencySelect v-on:changeCurrency="(value) => currency = value"/>
                </div>

                <SimpleInput type="text" placeholder="Regulatory Body of the Company/Licensing" :hint="true"
                             :hint-text="hintRegulatory" v-on:changeInput="(value) => companyLicensing = value"/>
              </div>

              <div class="captcha-box">
                <vue-recaptcha v-show="showRecaptcha" :sitekey="getCaptchaKey()"
                               size="normal"
                               theme="light"
                               hl="en"
                               :load-recaptcha-script="true"
                               @verify="handleSuccess"
                               @fail="handleError"
                               @expire="recaptchaExpired"
                               ref="vueRecaptcha">
                </vue-recaptcha>
                <div class="captcha-error" v-if="captchValid === false">Error captcha</div>
              </div>

              <div class="ui-btn -primary" theme="dark" v-on:click="send">Apply for business account</div>

              <div class="form-footer">
                <span>View Our</span><a class="-link" href="/northpeak_privacy_policy.pdf" target="_blank">Privacy
                Policy</a>
              </div>

            </form>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
import SimpleInput from "../components/SimpleInput";
import CountryInput from "../components/CountryInput";
import TelephoneInput from "../components/TelephoneInput";
import vueRecaptcha from 'vue3-recaptcha2';
import CurrencySelect from "../components/CurrencySelect";

export default {
  name: "Business",
  components: {CurrencySelect, TelephoneInput, CountryInput, SimpleInput, vueRecaptcha},
  data: () => ({
    hintRepresentative: 'Full name of an appointed official, director, or trustee - the person who acts on behalf of the legal entity/company based on legal grounds.',
    hintTurnover: 'The anticipated flow of incoming and outgoing funds in the account in EUR equivalent.',
    hintRegulatory: 'Name of the Government Authority issuing certificate or license for business activities (if applicable)',
    companyName: {
      value: '',
      hasError: false
    },
    regNumber: {
      value: '',
      hasError: false
    },
    countryOfIncorporation: {
      value: '',
      hasError: false
    },
    countryOfBusiness: {
      value: '',
      hasError: false
    },
    regAddress: {
      value: '',
      hasError: false
    },
    psyAddress: {
      value: '',
      hasError: false
    },
    website: {
      value: '',
      hasError: false
    },
    corporateEmail: {
      value: '',
      hasError: false
    },
    representative: {
      value: '',
      hasError: false
    },
    phone: {
      value: '',
      hasError: false
    },
    description: {
      value: '',
      hasError: false
    },
    currency: {
      value: '',
      hasError: false
    },
    turnoverCurrency: {
      value: '',
      hasError: false
    },
    companyLicensing: {
      value: '',
      hasError: false
    },
    processSending: false,
    captchValid: null,
    showRecaptcha: true,
    attachmentName: ''
  }),
  methods: {
    setPhone(value) {
      if (typeof value.phone !== "undefined" && value.phone !== "" && value.phone !== null) {
        this.phone.value = '+' + value.country.dialCode + value.phone
        this.phone.hasError = false
      } else {
        this.phone.hasError = true
      }
    },
    getCaptchaKey() {
      return process.env.VUE_APP_CAPTCHA_KEY
    },
    handleSuccess(response) {
      this.captchValid = true
      console.log(response)
    },
    recaptchaExpired() {
      this.captchValid = false
      this.$refs.vueRecaptcha.reset();
    },
    handleError() {
      this.captchValid = false
    },
    send() {
      this.emitter.emit('validate')
      if (this.captchValid === null) {
        this.captchValid = false
        return;
      }

      if (this.companyName.hasError
        || this.regNumber.hasError
        || this.countryOfIncorporation.hasError
        || this.countryOfBusiness.hasError
        || this.regAddress.hasError
        || this.psyAddress.hasError
        || this.website.hasError
        || this.corporateEmail.hasError
        || this.representative.hasError
        || this.phone.hasError
        || this.description.hasError
        || this.turnoverCurrency.hasError
        || this.companyLicensing.hasError
        || this.currency.hasError
        || !this.captchValid
      ) {
        return
      }

      if ( typeof this.companyName.value == 'undefined'
        || typeof this.regNumber.value == 'undefined'
        || typeof this.countryOfIncorporation.value.name == 'undefined'
        || typeof this.countryOfBusiness.value.name == 'undefined'
        || typeof this.regAddress.value == 'undefined'
        || typeof this.psyAddress.value == 'undefined'
        || typeof this.website.value == 'undefined'
        || typeof this.corporateEmail.value == 'undefined'
        || typeof this.representative.value == 'undefined'
        || typeof this.phone.value == 'undefined'
        || typeof this.description.value == 'undefined'
        || typeof this.turnoverCurrency.value == 'undefined'
        || typeof this.companyLicensing.value == 'undefined'
        || typeof this.currency.value == 'undefined'
      ) {
        return
      }

      if (this.processSending) {
        return
      }

      this.processSending = true

      let bodyFormData = new FormData();
      bodyFormData.append('companyName', this.companyName.value);
      bodyFormData.append('regNumber', this.regNumber.value);
      bodyFormData.append('countryOfIncorporation', this.countryOfIncorporation.value.name);
      bodyFormData.append('countryOfBusiness', this.countryOfBusiness.value.name);
      bodyFormData.append('regAddress', this.regAddress.value);
      bodyFormData.append('psyAddress', this.psyAddress.value);
      bodyFormData.append('website', this.website.value);
      bodyFormData.append('corporateEmail', this.corporateEmail.value);
      bodyFormData.append('representative', this.representative.value);
      bodyFormData.append('phone', this.phone.value);
      bodyFormData.append('description', this.description.value);
      bodyFormData.append('turnoverCurrency', this.turnoverCurrency.value);
      bodyFormData.append('companyLicensing', this.companyLicensing.value);
      bodyFormData.append('currency', this.currency.value);


      if (this.$route.name === "merchant") {
          bodyFormData.append('type', 'merchant');
      }

      this.axios.post('businessMailer.php', bodyFormData, {headers: {crossdomain: true},}).then(response => {
        this.isMailSend = response.data.status === 'ok'
        this.mailSendType = response.data.status === 'ok' ? 'send' : 'error'

        if (response.data.status === 'ok') {
          this.emitter.emit('openModal', {isOpen: true, componentModal: 'SuccessModal', data: {}})

          this.clear()
        } else {
          this.emitter.emit('openModal', {isOpen: true, componentModal: 'FailModal', data: {}})
        }

        this.$refs.vueRecaptcha.reset();
        this.processSending = false
        setTimeout(() => {
          this.isMailSend = false
          this.mailSendType = ''
        }, 1000)
      }).catch(e => {
        if (typeof e.message !== 'undefined' && e.message === 'Request failed with status code 404') {
          this.emitter.emit('openModal', {isOpen: true, componentModal: 'FailModal', data: {}})
        } else {
          this.clear()
          this.$refs.vueRecaptcha.reset();
        }

        this.processSending = false
        setTimeout(() => {
          this.isMailSend = false
          this.mailSendType = ''
        }, 1000)

        console.log(e)
      })
    },
    clear() {
      this.companyName.value = ''
      this.regNumber.value = ''
      this.countryOfIncorporation.value = ''
      this.countryOfBusiness.value = ''
      this.regAddress.value = ''
      this.psyAddress.value = ''
      this.website.value = ''
      this.corporateEmail.value = ''
      this.representative.value = ''
      this.phone.value = ''
      this.description.value = ''
      this.turnoverCurrency.value = ''
      this.companyLicensing.value = ''
      this.captchValid = null
      this.emitter.emit('clear')
    }
  }
}
</script>

<style lang="scss" scoped>
.input-flex-double {
  flex: 1 1;
  display: flex;
  flex-wrap: nowrap;
  align-items: baseline;

  .input-holder:nth-child(1) {
    margin-right: 30px !important;
    flex: 1 1 !important;

    @media (max-width: 768px) {
      margin-right: 30px !important;
    }
  }

  .input-holder:nth-child(2) {
    margin-right: 30px !important;
    margin-left: 0px !important;

    @media (max-width: 768px) {
      margin-right: 30px !important;
    }
  }
}
</style>