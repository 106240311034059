<template>
  <transition name="modal">
    <div class="ui-modal-overlay" v-if="openModal.isOpen">
      <div class="ui-modal-box">
          <component v-bind:is="openModal.componentModal" :data="openModal.data"></component>
      </div>
    </div>
  </transition>
</template>

<script>
import SuccessModal from "@/components/modalContent/SuccessModal";
import FailModal from "@/components/modalContent/FailModal";
export default {
  name: "Modal",
  components: { SuccessModal, FailModal },
  data() {
    return {
      openModal: {
        isOpen: false,
        componentModal: '',
        data: {}
      }
    }
  },
  mounted() {
    this.emitter.on("openModal", data => {
      if (data.isOpen) {
        document.body.classList.add('no-scroll')
      } else {
        document.body.classList.remove('no-scroll')
      }
      this.openModal = data;
    });
  },
}
</script>

<style lang="scss" scoped>
.modal-enter-active, .modal-leave-active {opacity: 0;}
.modal-enter .ui-modal-container, .modal-leave-active .ui-modal-container {-webkit-transform: scale(1.1);transform: scale(1.1);}
.modal-close {

}
</style>
