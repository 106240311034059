<template>
    <footer>
        <div class="footer-content">
            <div class="footer-stage">

                <div class="info-box">
                    <div class="-brand">
                        <div class="-footer-logo" v-on:click="changeRoute('frontpage')"></div>
                        <div class="socials-box">
                            <a href="https://twitter.com/NorthPeakPay" class="social-item -twitter" target="_blank"></a>
                            <a href="https://www.facebook.com/NorthPeakPay" class="social-item -facebook" target="_blank"></a>
                            <a href="https://www.linkedin.com/company/northpeak-pay/" class="social-item -linkedin" target="_blank"></a>
                        </div>
                    </div>
                    <div class="-contacts">
                        <div class="-item">1055 West Georgia Street, Suite #2400A, Vancouver, BC V6E 3P3, Canada</div>
                        <a href="mailto:office@northpeakpay.com" class="-item">office@northpeakpay.com</a>
                        <div class="-item">+1 (778) 728-17-88</div>
                    </div>
                </div>

                <div class="menu-box">
                    <div class="menu-holder">
                        <a class="menu-item" :class="$route.name === 'contacts' ? '-active' : ''" v-on:click="changeRoute('contacts')">Contact Us</a>
                        <a class="menu-item" :class="$route.name === 'about' ? '-active' : ''" v-on:click="changeRoute('about')">About Us</a>
                        <a class="menu-item" :class="$route.name === 'frontpage' ? '-active' : ''" v-on:click="changeRoute('frontpage')">Home</a>
                    </div>
                    <div class="menu-holder">
                        <a class="menu-item" :class="$route.name === 'services' ? '-active' : ''" v-on:click="changeRoute('services')">Services</a>
                        <a class="menu-item" href="/northpeak_terms_01.05.2022.pdf" target="_blank">Terms & Conditions</a>
                        <a class="menu-item" href="/northpeak_legal.pdf" target="_blank">Legal Information</a>
                    </div>
                    <div class="menu-holder">
                        <a class="menu-item" href="/northpeak_risk_appetite.pdf" target="_blank">Risk Appetite Policy</a>
                        <a class="menu-item" href="/northpeak_privacy_policy.pdf" target="_blank">Privacy Policy</a>
                        <a class="menu-item" href="/northpeak_cookies.pdf" target="_blank">Cookies Policy</a>
                    </div>
                </div>
            </div>
            <div class="footer-line"></div>
            <div class="footer-stage">

                <div class="info-box">
                    <div class="-copy-right">© Northpeak Pay Ltd. 2023,  All Rights Reserved</div>
                    <div class="-company-info">Northpeak Pay Ltd. is registered as Money Services Business (MSB) and regulated by The Financial Transactions and Reports Analysis Centre of Canada (FINTRAC). <br/> <b>License/Registration #: M20261842</b> </div>
                </div>
                <div class="socials-box">
                    <a href="https://twitter.com/NorthPeakPay" class="social-item -twitter" target="_blank"></a>
                    <a href="https://www.facebook.com/NorthPeakPay" class="social-item -facebook" target="_blank"></a>
                    <a href="https://www.linkedin.com/company/northpeak-pay/" class="social-item -linkedin" target="_blank"></a>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    export default {
        name: "Footer",
        methods: {
            changeRoute(name) {
                this.$router.push({name})
            }
        }
    }
</script>

<style scoped>

</style>