import Frontpage from "./views/Frontpage.vue";
import Services from "./views/Services.vue";
import About from "./views/About.vue";
import Contacts from "./views/Contacts.vue";
import Account from "./views/Account.vue";
import Login from "./views/Login.vue";
import Business from "./views/Business.vue";
import Individual from "./views/Individual.vue";
import {createRouter, createWebHistory} from "vue-router";

const routes = [
        {
            path: '/',
            name: 'frontpage',
            component: Frontpage,
            meta: {
                headerTheme: 'light',
                headerThemeInverse: 'dark'
            }
        },
        {
            path: '/services',
            name: 'services',
            component: Services,
            meta: {
                headerTheme: 'dark',
                headerThemeInverse: 'light'
            }
        },
        {
            path: '/about',
            name: 'about',
            component: About,
            meta: {
                headerTheme: 'light',
                headerThemeInverse: 'dark'
            }
        },
    {
        path: '/account',
        name: 'account',
        component: Account,
        meta: {
            headerTheme: 'dark',
            headerThemeInverse: 'light'
        }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            headerTheme: 'dark',
            headerThemeInverse: 'light'
        }
    },
    {
        path: '/business',
        name: 'business',
        component: Business,
        meta: {
            headerTheme: 'dark',
            headerThemeInverse: 'light'
        }
    },
    {
        path: '/merchant',
        name: 'merchant',
        component: Business,
        meta: {
            headerTheme: 'dark',
            headerThemeInverse: 'light'
        }
    },
    {
        path: '/individual',
        name: 'individual',
        component: Individual,
        meta: {
            headerTheme: 'dark',
            headerThemeInverse: 'light'
        }
    },
        {
            path: '/contacts',
            name: 'contacts',
            component: Contacts,
            meta: {
                headerTheme: 'light',
                headerThemeInverse: 'dark'
            }
        },
        //NOT FOUND ?
        { path: '/:catchAll(.*)', redirect: {name: 'frontpage'} },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            // BEFORE:
            // return { selector: to.hash }

            return { el: to.hash }
        }
        // always scroll to top
        return { top: 0 }
    },
})

export default router
