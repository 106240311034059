<template>
    <div class="content">
        <article id="front-main">
            <div class="container">
              <div class="module double">
                <div class="content-left">
                  <div class="-description">
                    <h1 class="-light">Be In Control<br/> of Your Financial Assets And Stay On Peak</h1>
                    <p class="-large -light">Managing money has never been so easy. NorthPeak Pay is your trustworthy assistant which allows you to spend less time compiling financial data and focus more on your personal and business goals.</p>
                    <div class="cta-box">
                      <a v-on:click="changeRoute('account')" class="ui-btn -primary" target="_blank" theme="light">get started now</a>
                      <a v-on:click="changeRoute('about')" class="ui-btn -link --icon-next" target="_blank" theme="light">learn more</a>
                    </div>
                  </div>
                </div>
                <div class="content-right">
                  <div class="-illustration">
                    <img class="--main-illustration" src="@/assets/images/frontpage/main-illustration.png"/>
                    <img class="--main-illustration-shadow"  src="@/assets/images/frontpage/main-illustration-shadow.png"/>
                  </div>
                </div>
              </div>
            </div>
        </article>
        <div class="context">
            <article id="front-services">
              <div class="container">
                <div class="module double">
                  <div class="content-left">
                    <div class="-description">
                      <h3 class="-dark">Our services</h3>
                      <h2 class="-dark">A Modern Approach<br/> To Financial Services</h2>
                      <p class="-medium -dark">Our digital payment solution will bring your finance management to the next level. We offer not just a payment platform, but a financial ecosystem. With NorthPeak Pay you can streamline all financial processes and get access to simpler and more profitable solutions.</p>
                      <a v-on:click="changeRoute('services')" class="ui-btn -primary --web" target="_blank" theme="dark">get more info</a>
                    </div>
                  </div>

                  <div class="content-right carousel">
                    <div class="services-box inner" ref="inner" :style="innerStyles">
                      <a class="-item" :class="item.isActive ? '--active' : ''" v-for="(item, index) in infos" :key="index" v-on:click="changeRoute('services', '#services' + index)">
                        <div class="-icon">
                          <img class="objectImg" :src="item.icon"/>
                        </div>
                        <div class="-info">
                          <h4 class="-dark">{{item.title}}</h4>
                          <p class="-small -dark">{{item.small}}</p>
                        </div>
                      </a>
                    </div>

                    <a href="/services" class="ui-btn -primary --mobile" target="_blank" theme="dark">get more info</a>
                  </div>

                </div>
              </div>
            </article>
            <article id="front-advantages">
                <div class="container">
                    <div class="module double -reverse">
                        <div class="content-left">
                            <div class="advantages-box">
                                <div class="advantage-item">
                                    <div class="-icon"><img class="objectImg" src="@/assets/images/frontpage/why-us-1.png"/></div>
                                    <div class="-info">
                                        <h4 class="-dark">Simple Application Process</h4>
                                        <p class="-dark -small">The verification is done remotely, you can open an account from anywhere in the world using any device</p>
                                    </div>
                                </div>
                                <div class="advantage-item">
                                    <div class="-icon"><img class="objectImg" src="@/assets/images/frontpage/why-us-2.png"/></div>
                                    <div class="-info">
                                        <h4 class="-dark">Your Finances Always Close At Hand</h4>
                                        <p class="-dark -small">Review your financial data and make payments in a few clicks with the NorthPeak Pay mobile banking</p>
                                    </div>
                                </div>
                                <div class="advantage-item">
                                    <div class="-icon"><img class="objectImg" src="@/assets/images/frontpage/why-us-3.png"/></div>
                                    <div class="-info">
                                        <h4 class="-dark">Transparency</h4>
                                        <p class="-dark -small">You don’t have to worry about hidden fees because we don’t have any</p>
                                    </div>
                                </div>
                                <div class="advantage-item">
                                    <div class="-icon"><img class="objectImg" src="@/assets/images/frontpage/why-us-4.png"/></div>
                                    <div class="-info">
                                        <h4 class="-dark">100% secure</h4>
                                        <p class="-dark -small">We are regulated by FINTRAC and maintain 100% liquidity</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content-right">
                            <div class="-description">
                                <h3 class="-dark">Why choose Us?</h3>
                                <h2 class="-dark">Safe and Modern Way<br/> To Manage Money</h2>
                                <p class="-medium -dark">At NorthPeak Pay we strive to be your reliable guide in the financial world. We want to make your money flow simple and fast. With us your money is kept in safeguarded accounts at reliable banks.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
            <article id="front-application">
                <div class="container">
                    <div class="module center">
                        <div class="-description">
                            <h3 class="-dark">Easy to apply</h3>
                            <h2 class="-dark">Get Started In A Few Minutes From Anywhere In The World</h2>
                        </div>
                        <div class="application-box">
                            <div class="application-item">
                                <div class="-icon">
                                    <img class="objectImg" src="@/assets/images/frontpage/easy-apply-1.png"/>
                                </div>
                                <h3 class="-dark">Select Profile Type</h3>
                                <p class="-small -dark">Based on your needs register business or individual profile</p>
                            </div>
                            <div class="application-chevron"></div>
                            <div class="application-item">
                                <div class="-icon">
                                    <img class="objectImg" src="@/assets/images/frontpage/easy-apply-2.png"/>
                                </div>
                                <h3 class="-dark">Register A Profile</h3>
                                <p class="-small -dark">Fill in the registration form: it takes no more than 5 minutes
                                </p>
                            </div>
                            <div class="application-chevron -center"></div>
                            <div class="application-item">
                                <div class="-icon">
                                    <img class="objectImg" src="@/assets/images/frontpage/easy-apply-3.png"/>
                                </div>
                                <h3 class="-dark">Verify Identity.<br/> Fully Online</h3>
                                <p class="-small -dark">We will verify you remotely: it takes up to 2 working days</p>
                            </div>
                            <div class="application-chevron"></div>
                            <div class="application-item">
                                <div class="-icon">
                                    <img class="objectImg" src="@/assets/images/frontpage/easy-apply-4.png"/>
                                </div>
                                <h3 class="-dark">Start Using NorthPeak Pay</h3>
                                <p class="-small -dark">After verification you can top up your account and start transacting</p>
                            </div>
                        </div>
                        <a v-on:click="changeRoute('account')" class="ui-btn -primary" target="_blank" theme="dark">get started now</a>
                    </div>
                </div>
            </article>
        </div>
    </div>
</template>

<script>
import img1 from '@/assets/images/frontpage/service-1.png'
import img2 from '@/assets/images/frontpage/service-2.png'
import img3 from '@/assets/images/frontpage/service-3.png'
import img4 from '@/assets/images/frontpage/service-4.png'
import img5 from '@/assets/images/frontpage/service-5.png'

export default {
    data: () => ({
      windowWidth: window.innerWidth,
      timer: null,
      iterator: 0,
      step: '',
      innerStyles: {},
      transitioning: false,
      infos: [
        {
          icon: img1,
          title: 'Individual and Business Accounts',
          small: 'Each account has an Individual IBAN for secure and fast transactions',
          isActive: true,
        },
        {
          icon: img2,
          title: 'Payments and Currency Exchange',
          small: 'Your account supports international SWIFT and SEPA payments in various currencies',
          isActive: false,
        },
        {
          icon: img3,
          title: 'Payment Cards',
          small: 'Use NorthPeak Pay debit cards to pay and withdraw money across the globe',
          isActive: false,
        },
        {
          icon: img4,
          title: 'Virtual Currency Transactions',
          small: 'Exchange and transfer virtual currency in a few clicks',
          isActive: false,
        },
        {
          icon: img5,
          title: 'Payment Processing',
          small: 'Get an access to card processing service',
          isActive: false,
        },
      ]
    }),
  mounted() {
      if (this.windowWidth < 1024) {
        this.setStep()
        this.resetTranslate()
      }


    this.timer = setInterval(() => {
      if (this.windowWidth < 1024) {
        this.next()
      } else {
        this.infos[this.iterator].isActive = true

        if (this.iterator !== 0) {
          this.infos[(this.iterator - 1)].isActive = false
        }

        if (this.iterator === 0) {
          this.infos[4].isActive = false
        }

        if (this.iterator < 4) {
          this.iterator++
        } else {
          this.iterator = 0
        }
      }
    }, 3000);
  },
  methods: {
    setStep () {
      const innerWidth = this.$refs.inner.scrollWidth
      const totalCards = this.infos.length
      this.step = `${(innerWidth / totalCards) - 20}px`
    },

    changeRoute(name, hash) {
      this.$router.push({name, hash})
    },

    next () {
      if (this.transitioning) return
      this.transitioning = true

      this.moveLeft()

      this.afterTransition(() => {
        const card = this.infos.shift()
        card.isActive = false

        this.infos.push(card)
        this.infos[0].isActive = true;

        this.resetTranslate()
        this.transitioning = false
      })
    },

    afterTransition (callback) {
      const listener = () => {
        callback()
        this.$refs.inner.removeEventListener('transitionend', listener)
      }
      this.$refs.inner.addEventListener('transitionend', listener)
    },

    resetTranslate () {
      this.innerStyles = {
        transition: 'none', // ❷
        transform: 'translateX(0)'
      }
    },

    moveLeft () {
      this.innerStyles = {
        transform: `translateX(-${this.step})`,
        transition : 'transform 1s ease-in'
      }
    }

  }
}
</script>

<style lang="scss" scoped>

    .context {
        /*background: radial-gradient(176.94% 75.1% at 109.62% 17.77%, rgba(119, 159, 211, 0.2) 47.37%, rgba(119, 159, 211, 0) 100%), #E7EBF4;*/
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("~@/assets/images/frontpage/background-web.png");

        @media (max-width:768px) {
            background-image: url("~@/assets/images/frontpage/background-mobile.png");
        }

      @media (max-width: 1023px) {
        .carousel {
          //overflow: hidden;
        }

        .inner {
          transition: transform 0.3s;
        }
      }
    }
</style>
