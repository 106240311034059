<template>
  <header :theme="getTheme().headerTheme">
    <div class="header-content">
      <div class="header-logo" v-on:click="changeRoute('frontpage')"></div>
      <div class="header-menu">
        <div class="menu-item" :class="$route.name === 'services' ? '-active' : ''"
             v-on:click="changeRoute('services')">our services
        </div>
        <div class="menu-item" :class="$route.name === 'about' ? '-active' : ''" v-on:click="changeRoute('about')">about
          us
        </div>
        <div class="menu-item" :class="$route.name === 'contacts' ? '-active' : ''"
             v-on:click="changeRoute('contacts')">contact us
        </div>
      </div>
      <div class="header-cta">
        <a v-on:click="changeRoute('account')" class="ui-btn -primary" target="_blank" :theme="getTheme().headerTheme">get account</a>
        <a v-on:click="changeRoute('login')" class="ui-btn -secondary" target="_blank" :theme="getTheme().headerThemeInverse">login</a>
      </div>
      <div class="header-mobile">
        <div class="mobile-btn" v-on:click="isMobile = !isMobile"></div>
      </div>
    </div>

    <div class="mobile-menu-overlay" ref="overlay" :class="isMobile ? 'opened' : ''">
      <div class="mobile-menu-holder" v-click-outside="handleClickOutside">

        <div class="mobile-menu-close" v-on:click="isMobile = !isMobile"></div>

        <div class="header-mobile-menu">
          <div class="menu-item" :class="$route.name === 'frontpage' ? '-active' : ''"
               v-on:click="changeRoute('frontpage')">home
          </div>
          <div class="menu-item" :class="$route.name === 'services' ? '-active' : ''"
               v-on:click="changeRoute('services')">our services
          </div>
          <div class="menu-item" :class="$route.name === 'about' ? '-active' : ''" v-on:click="changeRoute('about')">
            about us
          </div>
          <div class="menu-item" :class="$route.name === 'contacts' ? '-active' : ''"
               v-on:click="changeRoute('contacts')">contact us
          </div>
        </div>

        <div class="header-mobile-cta">
          <a v-on:click="changeRoute('account')"  class="ui-btn -primary" target="_blank" theme="light">get started now</a>
          <a v-on:click="changeRoute('login')" class="ui-btn -secondary" target="_blank" theme="dark">login</a>
        </div>
      </div>
    </div>

  </header>
</template>

<script>
import vClickOutside from "click-outside-vue3";

export default {
  name: "Header",
  directives: {
    clickOutside: vClickOutside.directive
  },
  data: () => ({
    isMobile: false,
  }),
  watch: {
    isMobile: function (val) {
      if (val) {
        document.body.classList.add('no-scroll')
      } else {
        document.body.classList.remove('no-scroll')
      }
    }
  },
  methods: {
    handleClickOutside(event) {
      if (event.target === this.$refs.overlay) {
        this.isMobile = false;
      }
    },
    changeRoute(name) {
      this.isMobile = false;
      this.$router.push({name})
    },
    getTheme() {
      let headerTheme = 'light';
      let headerThemeInverse = 'dark';

      if (typeof this.$route.meta.headerTheme === 'undefined') {
        headerTheme = 'light';
        headerThemeInverse = 'dark';
      } else {
        headerTheme = this.$route.meta.headerTheme;
        headerThemeInverse = this.$route.meta.headerThemeInverse;
      }

      return {headerTheme: headerTheme, headerThemeInverse: headerThemeInverse};
    }
  }
}
</script>

<style lang="scss" scoped>

</style>