<template>
  <div class="content">
    <article id="account-main">
      <div class="container">
        <div class="module center">
          <h2 class="-dark">Select Account To Apply For</h2>
          <div class="account-box">
            <a class="-item" href="https://register.northpeakpay.com/Applications/CRM/application_external.nsf/formCorporateApplication?OpenForm" target="_blank">
              <div class="-image">
                <img class="objectImg" src="@/assets/images/account/business-account-icon.png"/>
              </div>
              <div class="-label">Business Account</div>
            </a>
            <a class="-item" href="https://register.northpeakpay.com/Applications/CRM/application_external.nsf/formIndividualApplication?OpenForm" target="_blank">
              <div class="-image">
                <img class="objectImg" src="@/assets/images/account/idividual-account-icon.png"/>
              </div>
              <div class="-label">Individual Account</div>
            </a>
              <div class="-item" v-on:click="changeRoute('merchant')">
                  <div class="-image">
                      <img class="objectImg" src="@/assets/images/account/merchant-account-icon.png"/>
                  </div>
                  <div class="-label">Merchant Account</div>
              </div>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
export default {
  name: "Account",
  methods: {
    changeRoute(name) {
      this.$router.push({name})
    }
  }
}
</script>

<style lang="scss" scoped>

</style>